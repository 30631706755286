<template>
  <van-popup v-model="dialogShow" class="m-dialog">
    <div class="dialog__main">
      <div class="dialog__header">温馨提示</div>
      <div class="dialog__cont">
        查询链接所到商户无法换购饮料或者附近无显示换购点，可拨打活动热线（<a
          href="tel:400-818-5599"
          >400-818-5599</a
        >）登记，战马公司将安排当地工作人员给您推荐换购点，确保您的换购权益，感谢您的支持！
      </div>
      <div class="dialog__footer">
        <van-button
          type="info"
          @click="onConfirm"
          class="u-btn"
          v-tracking="{ userVisit: '40', sourceFlag: '2' }"
          >我知道了，继续查看换购点</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'BaseAgreementConfirmDialog',
  data() {
    return {
      dialogShow: false
    }
  },
  methods: {
    open() {
      this.dialogShow = true
    },
    onCancel() {
      this.dialogShow = false
      this.$emit('cancel')
    },
    onConfirm() {
      this.dialogShow = false
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.m-dialog {
  padding: 16px;
  width: 570px;
  height: 503px;
  background: #f42636;
  border-radius: 10px;
  .dialog__main {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 8px;
  }
  .dialog__header {
    padding: 63px 0 40px;
    font-size: 36px;
    color: #000;
    font-weight: bold;
    text-align: center;
  }
  .dialog__cont {
    height: 215px;
    padding: 0 60px;
    font-size: 24px;
    color: #000;
    text-indent: 2em;
    a {
      color: #000;
    }
  }
  .dialog__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 32px 51px;
    .van-button {
      width: 420px;
      border-radius: 10px;
      height: 68px;
    }
    .van-button--default {
      color: #d1d1d1;
    }
    .van-button--info {
      background-color: #2d5fe9;
    }
  }
  .link {
    color: #2d5fe9;
    text-decoration: none;
  }
}
</style>
