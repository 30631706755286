<template>
  <div v-html="$getConfig('activityRulesContent')"></div>
</template>

<script>
export default {
  name: 'BaseActivityRules',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
::v-deep .p1 {
  font-size: 24px;
  font-weight: bold;
  color: #010101;
}
</style>
