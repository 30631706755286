<template>
  <div class="container">
    <img width="100%" :src="$getConfig('indexBg')" />
    <div class="main">
      <div class="m-panel">
        <ul class="panel__main">
          <li>
            <p class="p1">
              <span class="p2">{{ queryNum }}</span
              >次
            </p>
            <p class="p1">查询次数</p>
          </li>
          <li>
            <p class="p1">
              <span class="p2">{{ currentQueryNum }}</span
              >次
            </p>
            <p class="p1">当前已被您扫</p>
          </li>
        </ul>
      </div>
      <div class="validation">
        <div class="validation__main">
          <p class="p1"><span class="p2">身份编号：</span>{{ qrCode }}</p>
        </div>
      </div>
      <!-- <div
        class="u-btn"
        @click="onOpenNearby"
        v-tracking="{ userVisit: '40', sourceFlag: '1' }"
      >
        <img src="@/assets/positioning-ico.png" class="ico" />查看附近换购点
      </div> -->
    </div>
    <BaseNav
      :indexs="[
        {
          index: 1,
          tracking: { userVisit: '42', sourceFlag: '3' }
        },
        {
          index: 2,
          tracking: { event: 'launch', userVisit: '42', sourceFlag: '4' }
        },
        {
          index: 3,
          tracking: { userVisit: '42', sourceFlag: '5' }
        }
      ]"
    />
    <BaseTipsDialog ref="tipsDialog" />
    <BaseExchangeDialog ref="exchangeDialog" @confirm="exchangeConfirm" />
    <BaseActivityRulesDialog
      ref="activityRuleDialog"
      @confirm="activityRulesConfirm"
    />
  </div>
</template>

<script>
import BaseTipsDialog from '@/components/base-tips-dialog/base-tips-dialog'
import BaseExchangeDialog from '@/components/base-exchange-dialog/base-exchange-dialog.vue'
import BaseActivityRulesDialog from '@/components/base-activity-rules-dialog/base-activity-rules-dialog.vue'
import preventBack from '@/mixins/prevent-browser-back'
import { mapGetters } from 'vuex'
import { setLocalStorage, getLocalStorage } from '@/utils/storage.js'

import {
  selectCountOfScanQrcodeByQrcode,
  selectCountOfScanQrcodeByQrcodeAndOpenId
} from '@/api/index.js'

export default {
  name: 'Index',
  components: {
    BaseTipsDialog,
    BaseExchangeDialog,
    BaseActivityRulesDialog
  },
  mixins: [preventBack],
  data() {
    return {
      queryNum: '',
      currentQueryNum: ''
    }
  },
  computed: {
    ...mapGetters(['qrCode'])
  },
  methods: {
    //查询次数（活动结束）
    getSelectCountOfScanQrcodeByQrcode() {
      return selectCountOfScanQrcodeByQrcode().then(({ data }) => {
        this.queryNum = data
        if (this.queryNum > 30) {
          this.$refs.tipsDialog.open()
        }
      })
    },
    //当前已被您扫(活动已结束)
    getSelectCountOfScanQrcodeByQrcodeAndOpenId() {
      return selectCountOfScanQrcodeByQrcodeAndOpenId().then(({ data }) => {
        this.currentQueryNum = data
      })
    },
    activityRulesConfirm() {
      setLocalStorage(this.$getConfig('activityRulesConfirmStorage'), true)
    },
    onOpenNearby() {
      this.$refs.exchangeDialog.open()
    },
    exchangeConfirm() {
      window.location.href = 'http://map.cnt-ad.net/admin/weixin/maps.php'
    }
  },
  mounted() {
    if (!getLocalStorage(this.$getConfig('activityRulesConfirmStorage'))) {
      this.$refs.activityRuleDialog.open()
    }

    this.$nextRequest(this.getSelectCountOfScanQrcodeByQrcode)
    this.$nextRequest(this.getSelectCountOfScanQrcodeByQrcodeAndOpenId)
  }
}
</script>

<style lang="scss" scoped>
.container {
  .main {
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 680px;
    width: 100%;
  }
  .m-panel {
    .panel__main {
      padding-top: 28px;
      display: flex;
      justify-content: center;
      width: 550px;
      height: 140px;
      margin-left: 150px;
      li {
        flex: 1 1 auto;
        text-align: center;
        .p1 {
          font-size: 24px;
          color: #333;
        }
        .p2 {
          font-size: 52px;
          color: #e7471f;
        }
      }
    }
  }
  .validation {
    padding-top: 35px;
    height: 200px;
    .validation__main {
      float: right;
      margin-right: 40px;
      width: 360px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      color: #333;
      .p1 {
        font-size: 20px;
      }
      .p2 {
        font-size: 24px;
      }
    }
  }
  .u-btn {
    .ico {
      width: 27px;
      height: 33px;
      margin-right: 8px;
    }
    width: 536px;
    color: #e7471f;
  }
  .rule {
    margin: 200px auto 0;
    padding: 40px 25px;
    position: relative;
    width: 665px;
    height: 1049px;
    background: url('../../assets/rect.png') no-repeat center top / 100% auto;
    .rule__label {
      position: absolute;
      right: 0;
      top: -130px;
      width: 229px;
      height: 162px;
      background: url('../../assets/chance.png') no-repeat center top / 100%
        auto;
    }
    .rule__title {
      margin: 0 auto 20px;
      width: 226px;
      height: 44px;
      background: url('../../assets/rule-title.png') no-repeat center top / 100%
        auto;
    }
    .rule__cont {
      font-size: 24px;
      color: #010101;
    }
    .p1 {
      margin-bottom: 5px;
      font-weight: bold;
    }
    .rule__box {
      height: 900px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      padding: 0 15px;
      ::v-deep a {
        color: #010101;
      }
    }
  }
}
</style>
