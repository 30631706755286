var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('img',{attrs:{"width":"100%","src":_vm.$getConfig('indexBg')}}),_c('div',{staticClass:"main"},[_c('div',{staticClass:"m-panel"},[_c('ul',{staticClass:"panel__main"},[_c('li',[_c('p',{staticClass:"p1"},[_c('span',{staticClass:"p2"},[_vm._v(_vm._s(_vm.queryNum))]),_vm._v("次 ")]),_c('p',{staticClass:"p1"},[_vm._v("查询次数")])]),_c('li',[_c('p',{staticClass:"p1"},[_c('span',{staticClass:"p2"},[_vm._v(_vm._s(_vm.currentQueryNum))]),_vm._v("次 ")]),_c('p',{staticClass:"p1"},[_vm._v("当前已被您扫")])])])]),_c('div',{staticClass:"validation"},[_c('div',{staticClass:"validation__main"},[_c('p',{staticClass:"p1"},[_c('span',{staticClass:"p2"},[_vm._v("身份编号：")]),_vm._v(_vm._s(_vm.qrCode))])])])]),_c('BaseNav',{attrs:{"indexs":[
      {
        index: 1,
        tracking: { userVisit: '42', sourceFlag: '3' }
      },
      {
        index: 2,
        tracking: { event: 'launch', userVisit: '42', sourceFlag: '4' }
      },
      {
        index: 3,
        tracking: { userVisit: '42', sourceFlag: '5' }
      }
    ]}}),_c('BaseTipsDialog',{ref:"tipsDialog"}),_c('BaseExchangeDialog',{ref:"exchangeDialog",on:{"confirm":_vm.exchangeConfirm}}),_c('BaseActivityRulesDialog',{ref:"activityRuleDialog",on:{"confirm":_vm.activityRulesConfirm}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }