<template>
  <van-popup
    v-model="dialogShow"
    class="m-dialog"
    :close-on-click-overlay="false"
  >
    <div class="dialog__main">
      <div class="dialog__header">温馨提示</div>
      <div class="dialog__cont">
        {{ $getConfig('tipsDialogContent') }}
      </div>
      <div class="dialog__footer">
        <van-button type="info" @click="onConfirm" class="u-btn"
          >我知道了</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'BaseAgreementConfirmDialog',
  data() {
    return {
      dialogShow: false
    }
  },
  methods: {
    open() {
      this.dialogShow = true
    },
    onCancel() {
      this.dialogShow = false
      this.$emit('cancel')
    },
    onConfirm() {
      this.dialogShow = false
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.m-dialog {
  padding: 16px;
  width: 570px;
  height: 503px;
  background: #f42636;
  border-radius: 10px;
  .dialog__main {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 8px;
  }
  .dialog__header {
    padding: 63px 0 80px;
    font-size: 36px;
    color: #000;
    font-weight: bold;
    text-align: center;
  }
  .dialog__cont {
    height: 175px;
    padding: 0 60px;
    font-size: 24px;
    color: #000;
    text-indent: 2em;
  }
  .dialog__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 32px 51px;
    .van-button {
      width: 250px;
      border-radius: 10px;
      height: 68px;
    }
    .van-button--default {
      color: #d1d1d1;
    }
    .van-button--info {
      background-color: #2d5fe9;
    }
  }
  .link {
    color: #2d5fe9;
    text-decoration: none;
  }
}
</style>
